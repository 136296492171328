// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  version:'2.5.0',

  apiEndpoint: 'https://test.api.eventhub.com.co',
  //apiEndpoint: 'http://localhost:3000',

  bucketUrl: 'https://d8a73z95r35xb.cloudfront.net/',

  mapbox: {
    accessToken: 'pk.eyJ1IjoiZ29sZGVubWFjZWRldiIsImEiOiJjazBnYnFxb3AwNWdqM2JuNWRwazZxMWY0In0._I3zpcM9QTHNqcXWjWpD6Q',
    mapStyle: 'mapbox://styles/goldenmacedev/ck49vpeap08b61cnubglcag7l'
  },
  firebase: {
    key: 'AAAAS6XeV-U:APA91bH8PvDDdTvuA1q2j9mv-rLZvyuanmwkOnZvaG_1RrGUpcbi6zgCDIS3Qa5S2DB8KqHzaSm88QWG-aj6dewnM-NzaPbczwfkAULMmZdZ77B4CiKE0gwwlqSPKspjORIV2VYCeYyG'
  },
  openpay: {
    //test
    //merchant_id: "mkhgxumuc2olejyqfxhy",
    //public_key: "pk_749639c4c36f4af6aeedd55f709be1f1"
    //prod
    merchant_id: 'mcpg7bukyqzaq593uevw',
    public_key: 'pk_4704f7b735184790add6825d7d92601f'
  },
  wompi: {
    //test
    //url: "https://sandbox.wompi.co/v1",
    //public_key: "pub_test_0YEFdO3kIz7mQwbwoVOCdSw8XgeTeVKt"
    //prod
    url: "https://production.wompi.co/v1",
    public_key: "pub_prod_6UQ3fTeVpdTvSfaE9jWkUPyfqe3BGEbh"
  },
  firebaseConfig: {
    apiKey: "AIzaSyA4EBdcbO9H9mxcTtVj3afusQEs70Ko8TA",
    authDomain: "eventhub-app-f7965.firebaseapp.com",
    projectId: "eventhub-app-f7965",
    storageBucket: "eventhub-app-f7965.appspot.com",
    messagingSenderId: "324905359333",
    appId: "1:324905359333:web:8011b006f4a4e20439010c",
    measurementId: "G-N59X8H6RG8"
  },
  facebookLoginConfig: {
    appId: '2781633055475287',
    appName: 'EventHub',
    clientToken: '9f3e9789498b41653e8d2b692e5f9235',
    fbLoginProtocolScheme: 'fb2781633055475287'
  },
  cs: {
    whatsapp: "573107858550",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
