import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {catchError, retry, throwError} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  readonly apiUrl = environment.apiEndpoint + '/app-version/';
  constructor(
    private http: HttpClient,
  ) { }

  fetch(){
    console.log("fetching version...")
    return this.http.get<any>(this.apiUrl + 'fetch').pipe(retry(1), catchError(this.processError));
  }

  async processError(err: any) {
    if (err.status === 0) {
      console.log("ERROR: " + err.message);
      return throwError(() => {
        console.log("ERROR: " + err.message);
      });
    }
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    console.log(message);
    return throwError(() => {
      message;
    });
  }

}
