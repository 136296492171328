import { NgModule, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { registerLocaleData } from "@angular/common";
import localeEsES from "@angular/common/locales/es-CO";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { TokenReceptorService } from "./services/token-receptor.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { environment } from "src/environments/environment";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import {NgxSpinnerModule} from "ngx-spinner";

registerLocaleData(localeEsES);

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    SweetAlert2Module.forRoot(),
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenReceptorService,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: "es_CO",
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
