import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  async open(
      message: string,
      position: any,
      duration: number = 2000,
      color: any = "dark"
  ) {
    const toast = await this.toastController.create({
      message: message,
      position: position,
      duration: duration,
      color: color,
    });

    await toast.present();
  }

  async generalToast(header: string, message: string, position: any, duration: number = 2000, icon: string = null) {
    const toast = await this.toastController.create({
      header: header,
      message: message,
      position: position,
      duration: duration,
      buttons: [
        {
          icon: icon,
          side: "start",
        },
      ],
      animated: true,
      cssClass: "toast-general middle-toast",
    });

    await toast.present();
  }

  async showError(message: string) {
    const toast = await this.toastController.create({
      header: "Error",
      message: message,
      position: "middle",
      duration: 2000,
      color: "danger",
      animated: true,
      cssClass: "toast-general middle-toast",
    });
    await toast.present();
  }
}
