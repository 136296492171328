import { Component } from "@angular/core";
import { NavController, AlertController } from "@ionic/angular";
import { Platform } from "@ionic/angular";
import { Location } from "@angular/common";
import { ToastService } from "./services/notifications/toast.service";
import { LocalNotificationsService } from "./services/local-notifications.service";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { App } from "@capacitor/app";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import {
  LocalNotifications,
  LocalNotificationSchema,
  ActionPerformed as ActionPerformedLocal,
} from "@capacitor/local-notifications";
import { Network } from "@capacitor/network";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es.json";
import { ValidatorService } from "./services/validator.service";
import { environment } from "../environments/environment";
import { Router } from "@angular/router";

// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
register();

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
      private navController: NavController,
      private alertController: AlertController,
      private platform: Platform,
      private location: Location,
      private localNotificationsService: LocalNotificationsService,
      private toastService: ToastService,
      private validatorService: ValidatorService,
      private router: Router
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    TimeAgo.addDefaultLocale(es);

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    if (Capacitor.getPlatform() == "android") {
      await this.channelBasic();
    }

    if (Capacitor.getPlatform() != "web") {
      await LocalNotifications.requestPermissions();

      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === "granted") {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener("registration", (token: Token) => {
        console.log("Push registration success, token: " + token.value);
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", (error: any) => {
        console.log("'Error on registration:" + error.message);
      });

      //Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
          "pushNotificationReceived",
          async (notification: PushNotificationSchema) => {
            let jsonNotification = notification;

            console.log("jsonNotification::", jsonNotification);

            await this.scheduleBasic(jsonNotification);
          }
      );

      //Method called when tapping on a notification
      PushNotifications.addListener(
          "pushNotificationActionPerformed",
          async (notification: ActionPerformed) => {
            let data = notification.notification.data;
            this.actionPerformed(notification, data);
          }
      );

      LocalNotifications.addListener(
          "localNotificationReceived",
          (notification: LocalNotificationSchema) => {
            console.log("localNotification receive:", notification);
          }
      );

      LocalNotifications.addListener(
          "localNotificationActionPerformed",
          (notification: ActionPerformedLocal) => {
            let data = notification.notification.extra.data;
            this.actionPerformed(notification, data);
          }
      );

      Network.addListener("networkStatusChange", async (status) => {
        console.log("El estado de la conexión ha cambiado::", status);
        const top = await this.alertController.getTop();
        let alertConnection = null;
        if (!status.connected) {
          alertConnection = await this.alertController.create({
            cssClass: "alert-error-connection",
            header: "Se ha perdido la conexión",
            message: "Recuperando conexión...",
            backdropDismiss: false,
            buttons: [
              {
                text: "Salir de Eventhub",
                cssClass: "quit-button",
                handler: () => {
                  App.exitApp();
                },
              },
            ],
            animated: true,
          });
          return await alertConnection.present();
        } else {
          if (top != undefined) {
            console.log("hay una alerta activa");
            this.alertController.dismiss();
          }
        }
      });
    }
  }

  async scheduleBasic(jsonNotification) {
    let notifications = null;

    if (Capacitor.getPlatform() == "android") {
      notifications = [
        {
          title: jsonNotification["title"],
          body: jsonNotification["body"],
          id: 1,
          extra: {
            data: jsonNotification["data"],
          },
          iconColor: "#000000",
          channelId: "pop-notification",
        },
      ];
    } else {
      notifications = [
        {
          title: jsonNotification["title"],
          body: jsonNotification["body"],
          id: 1,
          extra: {
            data: jsonNotification["data"],
          },
        },
      ];
    }

    await LocalNotifications.schedule({
      notifications: notifications,
    });
  }

  async channelBasic() {
    await LocalNotifications.createChannel({
      id: "pop-notification",
      name: "Pop notification",
      description: "Pop notification",
      importance: 5,
      visibility: 1,
    });
  }

  actionPerformed(notification, data) {
    switch (data.type) {
      case "buy-ticket-friend":
        this.localNotificationsService.goToWallet();
        break;
      case "cow-invitation":
        this.localNotificationsService.goToCow(data);
        break;
      case "event-invitation":
        this.localNotificationsService.goToEvent(data.idEvent);
        break;
      case "table-invitation":
        this.localNotificationsService.goToWallet();
        break;
      case "friend-request":
        this.localNotificationsService.goToUserShow(data.sender);
        break;
      case "group-invitation":
        this.localNotificationsService.goToGroup(data.idGroup);
        break;
      case "private-invitation":
        this.localNotificationsService.goToPrivate(data.idPrivate);
    }
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      const version = environment.version;
      this.validatorService.fetch().subscribe({ next: (response) => {
          if(response["version"] === undefined) {
            console.log("No version found!");
          } else {
            if (response["version"] <= version) {
              console.log("Current version! :)");
            } else {
              this.router.navigate(["/home"]);
            } }
        }});

      //await FacebookLogin.initialize({appId: environment.facebookLoginConfig.appId});
      if (Capacitor.getPlatform() != "web") {
        window.addEventListener("statusTap", function () {
          console.log("statusbar tapped");
        });

        StatusBar.setStyle({ style: Style.Dark });
      }

      App.addListener("backButton", async (data) => {
        if (
            this.location.isCurrentPathEqualTo("/pages/event/home") ||
            this.location.isCurrentPathEqualTo("/auth/signin")
        ) {
          const alert = await this.alertController.create({
            cssClass: "delete-invited",
            header: "Salir de EventHub?",
            message: "<p>¿Quieres salir de la aplicación?</p>",
            buttons: [
              {
                text: "Cancelar",
                role: "cancel",
              },
              {
                text: "Si",
                cssClass: "color-fucshia",
                handler: () => {
                  App.exitApp();
                },
              },
            ],
            animated: true,
          });
          return await alert.present();
        } else {
          this.navController.back();
        }
      });
    });
  }
}
