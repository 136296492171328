import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http'
import {Router} from '@angular/router'
import {environment} from '../../environments/environment';
import {jwtDecode} from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly URL_API = environment.apiEndpoint;

  constructor(private http: HttpClient, private router: Router) { }

  signUpClient(user) {
    return this.http.post<any>(this.URL_API + '/user/signup/client', user);
  }

  signInApp(user) {
    return this.http.post<any>(this.URL_API + '/user/signIn/app', user);
  }

  signInFaceBook(data: { id: string, name: string, image: string, first_name: string, last_name: string, email: string }): Observable<any> {
    return this.http.post<any>(this.URL_API + '/user/signIn/facebook/', data);
  }

  searchByEmailApp(data): Observable<any> {
    return this.http.post(this.URL_API + '/user/search/by/email/app/', data);
  }

  confirmData(user) {
    return this.http.post<any>(this.URL_API + '/user/confirm/data/web', user);
  }

  confirmPassword(data):Observable<any>{
    return this.http.post<any>(this.URL_API + '/user/confirm/password', data);
  }

  loggedIn() {
    if (localStorage.getItem('token')){
      if (this.tokenIsValid()){
        return localStorage.getItem('token');
      } else {
        this.logOut();
        return null
      }
    } else {
      this.logOut()
    }
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getUser() {
    if (localStorage.getItem('token')) {
      return jwtDecode(localStorage.getItem('token'));
    } else {
      return null;
    }
  }

  updateToken(id: string) {
    this.getTokenFormPerson(id).subscribe(response => {
      if (response.code == 200) {
        localStorage.setItem('token', response.data.token);
      }
    });
  }

  tokenIsValid(){
    const decoded = jwtDecode(localStorage.getItem('token'));
    if (decoded['exp'] > Date.now()/1000){
      console.log('Token Valid!');
      return true;
    }else{
      console.log('Token Invalid!');

      return false;
    }

  }

  getTokenFormPerson(id): Observable<any> {
    return this.http.get(this.URL_API + '/user/get/token/from/person/app/' + `${id}`);
  }

  logOut() {
    localStorage.removeItem('token');
    localStorage.clear();
    this.router.navigate(['/auth/signin']);
  }
}
