import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationsService {

  constructor(
    private navController: NavController
  ) { }

  goToEvent(idEvent) {
    this.navController.navigateForward(['/pages/event/show/' + idEvent], { animated: true, queryParams: { page: 1 } })
  }

  goToGroup(idGroup) {
    this.navController.navigateForward(['/pages/group/show/' + idGroup], { animated: true, queryParams: { page: 1 } })
  }

  goToPrivate(idPrivate) {
    this.navController.navigateForward(['/pages/private/show/' + idPrivate], { animated: true, queryParams: { page: 1 } })
  }

  goToUserShow(idUser) {
    this.navController.navigateForward(['/pages/user/show/' + idUser], { animated: true, queryParams: { page: 1 } })
  }

  goToCow(data) {
    this.navController.navigateForward('/pages/cow/request/' + data.idOrder, { animated: true });
  }

  goToWallet() {
    this.navController.navigateForward(['/pages/client/wallet/'], { animated: true });
  }
}
